import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { motion } from 'framer-motion'
import { rgba, darken } from 'polished'
import styled, { css } from 'styled-components'

export const ProgressBar = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: ${({ theme }) => getProjectThemeColor(theme.colors, theme.timer.progressColor || theme.screen.color)};
  width: 0;
  transition: all 1000ms linear;
`
export const Progress = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 16px;
  background: ${({ theme }) => rgba(getProjectThemeColor(theme.colors, theme.timer.progressColor), 0.2)};
`
export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  margin: -1rem;
`

export const Duration = styled.span`
  font-size: ${props => props.theme.fonts.fontSize['4xl']};
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: none;
  color: white;
  border: 0;
  transition: all 150ms ease-in-out;

  &:hover,
  &:focus {
    outline: 2px solid white;
    transform: scale(1.2);
  }
`
export const Actions = styled.span`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: ${({ theme }) => darken(0.2, getProjectThemeColor(theme.colors, theme.timer.backgroundColor))};
  transition: all 200ms ease-in-out;
`
export const Component = styled(motion.div)`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border-radius: 10rem;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  transform: rotate(-3deg)!important;
  font-weight: bold;

  ${props => props.theme && css`
    color: ${getProjectThemeColor(props.theme.colors, props.theme.timer.color || props.theme.screen.backgroundColor)};
    background-color: ${getProjectThemeColor(props.theme.colors, props.theme.timer.backgroundColor || props.theme.screen.color)};
    `
  }

  &:hover {
    ${Actions} {
      opacity: 1
    }
  }
`
