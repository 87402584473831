/* eslint-disable */
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import useRendererContext from '../../../hooks/useRendererContext'
import { RendererView } from '../../../types/slide'
import { Base, GridItem, Card } from './LinkGrid.styled'
import Image from '@pidk/renderer/src/components/Blocks/Image'
import { ArrowRightIcon } from '@pidk/common/src/components/Icons'

type BlockComponent = React.FC<ILinkGrid> & {
  schema: IFieldSchema
  Styled?: any
}

interface ILink {
  id: string
  label: string
  image: string
  slide: {
    deck: string
    slide: string
  }

}
interface ILinkGrid {
  id: string
  columnCount: number
  links: ILink[]
  showImages: boolean
  hideLabels: boolean
}

const LinkGrid: BlockComponent = ({
  id,
  columnCount,
  links,
  showImages,
  hideLabels
}: ILinkGrid) => {

  const { onNavigate } = useRendererContext()

  const handleButtonClick = (slide) => {
    if (slide) {
      onNavigate(RendererView.DECK, slide.deck, slide.slide)
    }
  }

  return (
    <Base key={id} $columnCount={columnCount || 1}>
      {links && links.map(l => {

        return (
          <GridItem key={l.id}>
            <Card
              $hasImage={(showImages ? true : false)}
              onClick={() => handleButtonClick(l.slide)}
            >
              {showImages && (
                <Image image={l.image} />
              )}
              {!hideLabels && (
                <div>
                  <span>{l.label}</span>
                  <ArrowRightIcon
                    width={24}
                    height={24}
                  />
                </div>
              )}
            </Card>
          </GridItem>
        )
      })}
    </Base>
  )
}

LinkGrid.schema = {
  name: 'Link Grid',
  key: 'link-grid',
  defaultFieldValues: {
    columnCount: 1,
    showImages: false,
    hideLabels: false
  },
  fields: [
    {
      key: 'links',
      type: FieldType.REPEATER,
      label: 'Links',
      primaryKey: 'label',
      button: 'Add link',
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label',
        },
        {
          key: 'slide',
          type: FieldType.SLIDE,
          label: 'Slide',
        },
        {
          key: 'image',
          type: FieldType.MEDIA,
          label: 'Image',
          maxFiles: 1,
          mediaType: MediaType.IMAGE,
        }
      ]
    },
    {
      key: 'columnCount',
      type: FieldType.RANGE,
      label: 'Columns',
      instructions: 'Change the format of the grid',
      min: 1,
      max: 5,
      step: 1
    },
    {
      key: 'showImages',
      type: FieldType.BOOLEAN,
      label: 'Show Image',
      instructions: 'Change the button to show image defined in Deck'
    },
    {
      key: 'hideLabels',
      type: FieldType.BOOLEAN,
      label: 'Hide Label',
      instructions: 'Hide the label, make sure to add an image beforehand.'
    }
  ]
}

LinkGrid.Styled = Base

export default LinkGrid
