/* eslint-disable */
import type { ReactNode } from 'react'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import useProjectMedia from '../../../hooks/useProjectMedia'
import { Container, BackgroundImage, Base, Marker, Hotspot } from './Hotspots.styled'
import ActionsPropSchema from '@pidk/compose/src/schemas/properties/actions'
import useBlockAction, { IBlockAction } from '../../../hooks/useBlockAction'

type BlockComponent = React.FC<IHotspots> & {
  schema: IFieldSchema
  Styled?: any
}

interface IHotspotsOption extends IBlockAction {
  id: string
  label?: string
  position: {
    x: string
    y: string
    width: string
    height: string
  }
}

interface IHotspots {
  id: string
  onPopup?: (type: string, component: ReactNode, timeout?: number, closable?: boolean) => void
  options?: IHotspotsOption[]
  backgroundImage?: string
  showMarkers: boolean
  showLabels: boolean
  isEditable: boolean
}

const Hotspots: BlockComponent = ({
  id,
  onPopup,
  options,
  backgroundImage,
  showMarkers = false,
  showLabels = false,
  ...props
}: IHotspots) => {
  const [background] = useProjectMedia([backgroundImage])
  const handleAction = useBlockAction({
    onPopup
  })

  return (
    <Base>
      <Container>
        {backgroundImage && (
          <BackgroundImage src={background?.url} />
        )}

        {options?.map((option) => {
          return (
            <Hotspot
              key={option.id}
              top={option.position?.y}
              left={option.position?.x}
              width={option.position?.width}
              height={option.position?.height}
              showBackground={props.isEditable}
              onClick={handleAction(option)}
              title={option.label}
            >
              {showMarkers && (
                <Marker>
                  {showLabels && option.label}
                </Marker>
              )}
            </Hotspot>
          )
        })}
      </Container>
    </Base>
  )
}

Hotspots.schema = {
  name: 'Hotspots',
  key: 'hotspots',
  defaultFieldValues: {
    label: 'Hotspots',
    type: 'link'
  },
  fields: [{
    key: 'backgroundImage',
    type: FieldType.MEDIA,
    label: 'Background Image',
    maxFiles: 1,
    mediaType: MediaType.IMAGE
  }, {
    key: 'showMarkers',
    type: FieldType.BOOLEAN,
    label: 'Show Markers'
  }, {
    key: 'showLabels',
    type: FieldType.BOOLEAN,
    label: 'Show Labels'
  }, {
    key: 'options',
    type: FieldType.REPEATER,
    label: 'Options',
    primaryKey: 'label',
    fields: [
      {
        key: 'label',
        type: FieldType.TEXT,
        label: 'Label'
      },
      {
        key: 'position',
        type: FieldType.COORDINATES,
        label: 'Position',
      },
      ...ActionsPropSchema.fields
    ]
  }]
}

Hotspots.Styled = Base

export default Hotspots
