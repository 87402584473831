import type { IFieldSchema } from './../../types/fields'
import { FieldType, MediaType } from './../../types/fields'

const BackgroundPropSchema: IFieldSchema = {
  name: 'Background',
  key: 'background',
  defaultFieldValues: {},
  fields: [
    {
      key: 'background',
      type: FieldType.GROUP,
      label: 'Background',
      fields: [
        {
          key: 'color',
          type: FieldType.COLOR,
          label: 'Color'
        },
        {
          key: 'image',
          type: FieldType.MEDIA,
          label: 'Image',
          maxFiles: 1,
          mediaType: MediaType.IMAGE
        },
        {
          key: 'position',
          label: 'Position',
          type: FieldType.CHOICE,
          component: 'button',
          conditional: [{
            field: 'image',
            operator: '!=',
            value: ''
          }],
          options: [
            {
              value: 'top left',
              label: 'TL'
            },
            {
              value: 'top center',
              label: 'TC'
            },
            {
              value: 'top right',
              label: 'TR'
            },
            {
              value: 'center left',
              label: 'CL'
            },
            {
              value: 'center center',
              label: 'CC'
            },
            {
              value: 'center right',
              label: 'CR'
            },
            {
              value: 'bottom left',
              label: 'BL'
            },
            {
              value: 'bottom center',
              label: 'BC'
            },
            {
              value: 'bottom right',
              label: 'BR'
            }
          ]
        },
        {
          key: 'size',
          label: 'Size',
          type: FieldType.CHOICE,
          component: 'button',
          conditional: [{
            field: 'image',
            operator: '!=',
            value: ''
          }],
          options: [
            {
              value: 'cover',
              label: 'Cover'
            },
            {
              value: 'contain',
              label: 'Contain'
            },
            {
              value: 'fill',
              label: 'Fill'
            }
          ]
        },
        {
          key: 'repeat',
          label: 'Repeat',
          type: FieldType.CHOICE,
          component: 'button',
          conditional: [{
            field: 'image',
            operator: '!=',
            value: ''
          }],
          options: [
            {
              value: 'no-repeat',
              label: 'None'
            },
            {
              value: 'repeat-x',
              label: 'X'
            },
            {
              value: 'repeat-y',
              label: 'Y'
            },
            {
              value: 'repeat',
              label: 'Repeat'
            }
          ]
        }
      ]
    }
  ]
}

export default BackgroundPropSchema
