import type { IFieldSchema } from './../../types/fields'
import { FieldType } from './../../types/fields'

const SpacingPropSchema: IFieldSchema = {
  name: 'Spacing',
  key: 'spacing',
  defaultFieldValues: {},
  fields: [
    {
      key: 'spacing',
      type: FieldType.GROUP,
      label: 'Spacing',
      fields: [
        {
          key: 'width',
          label: 'Width',
          type: FieldType.RANGE,
          min: 1,
          max: 12,
          step: 1
        },
        {
          key: 'height',
          label: 'Height',
          type: FieldType.RANGE,
          min: 1,
          max: 6,
          step: 1
        },
        {
          key: 'padding',
          label: 'Padding',
          type: FieldType.CHOICE,
          component: 'button',
          options: [
            {
              value: '0',
              label: 'None'
            },
            {
              value: 'sm',
              label: 'Small'
            },
            {
              value: 'md',
              label: 'Medium'
            },
            {
              value: 'lg',
              label: 'Large'
            }
          ]
        },
        {
          key: 'margin',
          label: 'Margin',
          type: FieldType.CHOICE,
          component: 'button',
          options: [
            {
              value: '0',
              label: 'None'
            },
            {
              value: 'sm',
              label: 'Small'
            },
            {
              value: 'md',
              label: 'Medium'
            },
            {
              value: 'lg',
              label: 'Large'
            },
            {
              value: 'auto',
              label: 'Auto'
            }
          ]
        }
      ]
    }
  ]
}

export default SpacingPropSchema
