import type { MediaFormatted } from '@pidk/api/src/types/api'

import { Component, Card } from './../Memory.styled'

interface IMemoryItem {
  id: string
  parentId: string
  image?: MediaFormatted
  label?: string
}
interface IGrid {
  columnCount: number
  defaultImage: MediaFormatted
  items: IMemoryItem[]
  visibleItems: number[]
  finishedItems: number[]
  handleItemClick: (i:number) => void
}

const variants = {
  hidden: i => ({
    scale: 1,
    rotateZ: i % 2 ? -1 : 1
  }),
  revealed: {
    scale: 1.1,
    rotateX: '-2deg'
  },
  finished: {
    scale: 1,
    transition: {
      delay: 1
    }
  }
}

export const Grid = ({ columnCount, defaultImage, items, visibleItems, finishedItems, handleItemClick }: IGrid) => {

  return (
    <Component $columnCount={columnCount}>
      {items.map((item, i) => {
        return (
          <Card
            key={item.id}
            custom={i}
            onClick={() => handleItemClick(i)}
            whileHover={(!visibleItems.includes(i) && !finishedItems.includes(i) ? { scale: 1.05, rotateX: '-1deg' } : {})}
            variants={variants}
            animate={(finishedItems.includes(i) ? 'finished' : (visibleItems.includes(i) ? 'revealed' : 'hidden'))}
            className={((visibleItems.includes(i) || finishedItems.includes(i) ? 'is-revealed' : ''))}
          >
            <div className='content'>
              <div className='back'>
                <img src={defaultImage?.url} />
              </div>
              <div className='front'>
                {item.image ? (
                  <img src={item.image?.url} />
                ):
                  <span className='label'>{item.label}</span>}
              </div>
            </div>
          </Card>
        )
      })}
    </Component>
  )
}

export default Grid
