// eslint-disable react/jsx-no-bind
import { PlayIcon, PauseIcon, StopIcon } from '@pidk/common/src/components/Icons'
import transitions from '@pidk/common/src/lib/transitions'
import SFX from '@pidk/common/src/sfx'
import { transform, useAnimation } from 'framer-motion'
import * as React from 'react'
import useSound from 'use-sound'

import { Progress, ProgressBar, Component, Wrapper, Duration, Actions, Button } from './Timer.styled'

interface TimerProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string
  // eslint-disable-next-line react/no-unused-prop-types
  duration: number
  // eslint-disable-next-line react/no-unused-prop-types
  onEnd?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  onTick?: void
  // eslint-disable-next-line react/no-unused-prop-types
  onStopped?: void
  // eslint-disable-next-line react/no-unused-prop-types
  onPaused?: void
  // eslint-disable-next-line react/no-unused-prop-types
  sfx?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  isRunning?: boolean
}

// const mapRemainingToBg = transform([2, 6], ["#182B9F", "#02BEFF"])
const mapRemainingToSpringVelocity = transform([0, 0], [20, 0])

const timeStamp = (s) => { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

export const Timer = (props: TimerProps) => {

  const [timer, setTimer] = React.useState(props.duration)
  const [isRunning, setRunning] = React.useState(props.isRunning)
  const [percentage, setPercentage] = React.useState(100)
  const controls = useAnimation()
  const [playSuccess] = useSound(SFX.correctAlert)
  const [clickFX] = useSound(SFX.click)

  const onFinishedTimer = React.useCallback(() => {
    setRunning(false)
    playSuccess()
    return props.onEnd()
  }, [])

  React.useEffect(() => {

    setPercentage((timer / props.duration) * 100)

    let timeLeft
    if (isRunning) {

      controls.start({
        scale: 1,
        transformOrigin: 'center',
        transition: {
          type: 'spring',
          velocity: mapRemainingToSpringVelocity(timer),
          stiffness: 200,
          damping: 200
        }
      })

      timeLeft = setTimeout(function () {

        if (timer === 0) {
          onFinishedTimer()
        } else {
          setTimer(timer - 1)
        }

      }, 1000)

      return () => {
        clearTimeout(timeLeft)
      }

    } else {
      clearTimeout(timeLeft)
    }

  }, [isRunning, timer, props.duration, onFinishedTimer, controls])

  return (
    <>
      {isRunning && (
        <Progress>
          <ProgressBar
            style={{
              width: percentage + '%'
            }}
          />
        </Progress>
      )}
      <Wrapper
        initial={transitions['fadeRight'].initial}
        animate={transitions['fadeRight'].animate}
        transition={{ delay: 1.2, ease: 'easeInOut' }}
      >
        <Component
          animate={controls}
          onClick={() => setRunning(!isRunning)}
        >
          <Duration>
            {timeStamp(timer)}
          </Duration>
          <Actions>
            <>
              {isRunning && (
                <Button
                  onClick={() => { setRunning(false), clickFX() }}
                >
                  <PauseIcon
                    width={32}
                    height={32}
                  />
                </Button>
              )}
              {!isRunning && (
                <Button
                  onClick={() => { setRunning(true), clickFX() }}
                >
                  <PlayIcon
                    width={32}
                    height={32}
                  />
                </Button>
              )}
              {timer < props.duration && (
                <Button
                  onClick={() => { setTimer(props.duration) }}
                >
                  <StopIcon
                    width={32}
                    height={32}
                  />
                </Button>
              )}
            </>
          </Actions>
        </Component>
      </Wrapper>
    </>
  )

}

Timer.defaultProps = {
  isRunning: true
}

export const TimerButton = (props: TimerProps) => {

  const [isRunning, setRunning] = React.useState(false)

  React.useEffect(() => {
    setRunning(false)
  }, [props.duration])

  const onTimeEnded = React.useCallback(() => {
    setRunning(false)

    // TODO: check how I can disable this if not needed
    if (props.onEnd) {
      props.onEnd()
    }

  }, [])

  if (props.duration > 0) {
    return (
      <Timer
        id={props.id}
        isRunning={isRunning}
        duration={props.duration}
        onEnd={onTimeEnded}
      />
    )
  }

  return null

}

export default Timer
