import { Modal } from '@pidk/common/src/components/Modal'
import Image from '@pidk/renderer/src/components/Blocks/Image'
import { backgroundImageTransition, slideContentTransition } from '@pidk/renderer/src/utils/transitions'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import useProjectMedia from '../../../hooks/useProjectMedia'
import type { SlideComponent } from '../../../types/slide'
import { getBlockComponent } from '../../Blocks'
import { TimerButton } from '../../common/Timer/Timer'
import Component, { Inner, Title, Logo, Background } from '../Slide.styled'
import Area from './../../Area'

const SlideLayout: SlideComponent = ({
  logo,
  slide,
  isEditable,
  selectedAreaId,
  onAreaClick,
  full
}) => {
  const {
    id,
    properties,
    blocks,
    areas,
    data
  } = slide

  const [popup, setPopup] = useState({
    type: null,
    component: null,
    timeout: null,
    closable: true
  })

  const [blockImage] = useProjectMedia([properties?.background?.image])

  const screenRef = useRef<HTMLDivElement>(null)

  const backgroundProperties = {
    ...properties?.background,
    image: blockImage?.url
  }

  useEffect(() => {
    setPopup(null)
  }, [id])

  const handlePopup = (type, component, timeout, closable) => {

    if (isEditable) return

    setPopup({
      type,
      timeout,
      component,
      closable
    })
  }

  return (
    <Component
      ref={screenRef}
      $background={properties?.background}
      $full={full}
    >
      <AnimatePresence exitBeforeEnter>
        {properties?.background?.image && (
          <Background
            key={properties?.background?.image}
            $background={backgroundProperties}
            {...backgroundImageTransition({ ...properties.transition })}
          />
        )}
      </AnimatePresence>

      {data?.label && (
        <Title key={data.label}>{data.label}</Title>
      )}

      {data?.timer && (
        <TimerButton
          id={slide.id}
          key={slide.id}
          duration={parseInt(data?.timer) * 60}
        />
      )}
      <AnimatePresence exitBeforeEnter>
        <Inner
          key={`slide-${id}`}
          $colors={properties?.colors}
          {...slideContentTransition({ ...properties.transition })}
        >
          {areas.map((a, i) => {

            const getAreaBlocks = blocks?.filter(b => b.area === a.id).sort((a, b) => a.order - b.order)

            return (
              <Area
                key={a.id}
                onClick={() => isEditable && onAreaClick((selectedAreaId === a.id ? null : a.id))}
                isActive={selectedAreaId === a.id}
                tabIndex={i}
                isEditable={isEditable}
                properties={a.properties}
              >
                {getAreaBlocks?.map((block) => {
                  const BlockComponent = getBlockComponent(block.type)

                  return (
                    <BlockComponent
                      key={block.key}
                      id={slide.id + '-' + block.key}
                      onPopup={handlePopup}
                      isEditable={isEditable}
                      isHost
                      screenRef={screenRef}
                      {...block.fieldsValues}
                    />
                  )
                })}
              </Area>
            )
          })}
        </Inner>
      </AnimatePresence>
      <AnimatePresence>
        {popup?.component && (
          <Modal
            key='modal'
            type={popup.type}
            timeout={popup.timeout}
            onClose={() => setPopup({
              type: null,
              component: null,
              timeout: null,
              closable: true
            })}
          >
            {popup.component}
          </Modal>
        )}
      </AnimatePresence>

      {data?.showLogo && logo && logo?.image && (
        <Logo
          $position={logo.position}
          $size={logo.size}
        >
          <Image image={logo.image} />
        </Logo>
      )}
    </Component>
  )
}

SlideLayout.schema = {
  name: 'Layout',
  key: 'layout',
  defaultFieldValues: {},
  fields: []
}

export default SlideLayout
