import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { Option } from './../../common/Option'

export const Base = styled.div<any>`
  width: 100%;
  position: relative;
`

export const Grid = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;

  ${({ $isFixed }) => $isFixed && css`
      position: fixed;
      bottom: 0;
      height: auto;
      z-index: 10;
      margin-bottom: 2rem;
  `}
  ${({ $wrapped }) => $wrapped && css`
      flex-wrap: wrap;
      height: auto;
  `}

  ${({ $isDropzone }) => $isDropzone && css`
      flex-wrap: wrap;
      > * {
        flex: 1;
      }
  `}

  ${Option} {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      position: relative;
      font-weight: bold;
      font-size: 1em;
      font-size: clamp(1em, 1.1vw,  32px);
      line-height: 1.1;

      .background {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .label {
        text-align: center;
        font-size: inherit;
        justify-content: center;
        position: relative;
      }

      .badge {
        font-size: 0.8em;
        left: 50%;
        transform: translateX(-50%);
        min-height: 2rem;
        width: 100%;
        max-width: 80%;
      }
  }
`

const Zone = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 20vh;
  width: 20vh;
  font-size: 2vh;
`

export const Dropzone = styled(Zone)<any>`
  outline: 2px solid rgba(255,2555,255, 0);
  transition: transform 200ms ease-in-out;

  ${props => props.$isActive && css`
    outline-color: white;
    transform: scale(1.05);
  `}

  ${Option} {

    pointer-events: none;

    ${({ theme }) => css`
      background-color: ${getProjectThemeColor(theme.colors, (theme.connect?.dropzone?.backgroundColor || 'transparent'))};
      color: ${getProjectThemeColor(theme.colors, (theme.connect?.dropzone?.color || 'white'))};
    `}

    ${({ $isDone, theme }) => $isDone && css`
      background-color: ${getProjectThemeColor(theme.colors, (theme.connect?.option?.done?.backgroundColor || 'transparent'))};
      color: ${getProjectThemeColor(theme.colors, (theme.connect?.option?.done?.color || 'white'))};
      box-shadow: 0 0 0px 4px ${getProjectThemeColor(theme.colors, theme.connect?.dropzone?.backgroundColor || 'transparent')};
    `}
  }
`

export const Dragzone = styled(Zone)`
  box-shadow: ${({ theme }) => theme.dragzone?.boxShadow || 'rgba(0, 0, 0, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset'};

  ${Option} {
    ${({ theme }) => css`
        background-color: ${getProjectThemeColor(theme.colors, (theme.connect?.option?.default?.backgroundColor || 'secondary'))};
        color: ${getProjectThemeColor(theme.colors, (theme.connect?.option?.default?.color || 'white'))};

        &:hover {
          background-color: ${getProjectThemeColor(theme.colors, (theme.connect?.option?.default?.backgroundColor || 'secondary'))};
          color: ${getProjectThemeColor(theme.colors, (theme.connect?.option?.default?.color || 'white'))};
        }
    `}
  }
`

export const DragItem = styled(motion.div)`
  height: 20vh;
  width: 20vh;
  display: block;
  box-shadow: ${({ theme }) => theme.shadows['2xl']};
`

export const Label = styled.span<any>`
  display: block;
`
