import { isMatchingArray, shuffle as shuffleArray, isMatchingArrayDifferences } from '@pidk/common/src/utils/arrays'
import * as React from 'react'

interface IUseSort {
  id: string
  options: any[]
  shuffle: boolean
  onChecked: (isCorrect: boolean, { selection }) => void
}

type SortState = 'init' | 'selected' | 'results' | 'voting' | 'feedback'

interface ISortReturn {
  state: SortState
  selection: any[]
  actions: {
    handleReorder: (selection) => void
    checkAnswer: (e) => void
    reset: () => void
    revealCorrectAnswer: () => void
  }
  attemptCount: number
}
const useSort = ({ id, options, shuffle, onChecked }: IUseSort): ISortReturn => {

  const [state, setState] = React.useState<SortState>('init')
  const [selection, setSelection] = React.useState([])
  const [ attemptCount, setAttemptCount] = React.useState(0)

  const actions = {
    handleReorder(selection) {

      if (state !== 'feedback') {

        setSelection(selection)
        setState('selected')

      }

    },
    checkAnswer(e) {
      e.preventDefault()

      let isCorrect = null
      let errors = []

      if (options.length > 0) {
        isCorrect = isMatchingArray(selection, options)
        errors = isMatchingArrayDifferences(selection, options)
      }

      setState('results')
      onChecked(isCorrect, { selection: errors })
      setAttemptCount(attemptCount + 1)
    },
    revealCorrectAnswer() {
      setState('feedback')
      setSelection(options)
    },
    reset() {
      setState('init')
      setAttemptCount(0)
      if(shuffle) {
        setSelection(shuffleArray(options))
      } else {
        setSelection(options)
      }

    }
  }

  React.useEffect(() => {
    actions.reset()
  }, [id])

  React.useEffect(() => {
    actions.reset()
  }, [options])

  return { state, selection, actions, attemptCount }

}

export default useSort
