import { lazy, Suspense } from 'react'

const Whiteboard = lazy(() => import('./Whiteboard'))

// Wrap with Suspense to avoid error
const WhiteBoardWrapper = (props) => {
  return (
    <Suspense fallback='test'>
      <Whiteboard {...props} />
    </Suspense>
  )
}
export default WhiteBoardWrapper
