import { useContext } from 'react'

import LessonStateContext from '../contexts/lesson'
import type { ILessonStateContext } from './../contexts/lesson'

const useLessonState = (): ILessonStateContext => {
  return useContext(LessonStateContext)
}

export default useLessonState
