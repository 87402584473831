import { createContext } from 'react'

export interface ILessonState {
  [deck: string]: {
    [slide: string]: unknown
  }
}
export interface ILessonStateContext {
  state?: ILessonState
  currentSlideState: (deck: string, slide: string) => void
  storeSlideState: (deck: string, slide: string, data: unknown) => void
  clearState: () => void
}

const LessonStateContext = createContext<ILessonStateContext>(undefined)

export default LessonStateContext
