import type {
  IProjectCreateBody,
  IProjectLoginBody,
  IProjectUpdateBody
} from '@pidk/api/src/routes/projects/projects.controller'
import type { ProjectFormatted , DeckFormatted } from '@pidk/api/src/types/api'
import type { DeckParsed, Project, ProjectTheme } from '@pidk/compose/src/types/compose'

import { apiRequest } from '@/utils/apiRequest'
import { getProjectCookie } from '@/utils/tokenStore'

export const createProject = async (newProject: IProjectCreateBody): Promise<ProjectFormatted> => {
  return apiRequest('projects', {
    method: 'POST',
    body: newProject
  })
}

export const updateProject = async (id: string, updatedProject?: IProjectUpdateBody): Promise<ProjectFormatted> => {
  return apiRequest(`projects/${id}`, {
    method: 'PUT',
    body: updatedProject
  })
}

interface LoginProjectResponse {
  projectToken: string
}

export const loginProject = async (id: string, body: IProjectLoginBody): Promise<LoginProjectResponse> => {
  return apiRequest(`projects/${id}/login`, {
    method: 'POST',
    body
  })
}

export const authenticateProject = async (id: string): Promise<void> => {
  return apiRequest(`projects/${id}/authenticate`, {
    method: 'POST',
    body: {
      projectToken: getProjectCookie()
    }
  })
}

export const archiveProject = async (id: string): Promise<ProjectFormatted> => {
  return apiRequest(`projects/${id}`, {
    method: 'DELETE'
  })
}

export const saveProject = async (id: string): Promise<ProjectFormatted> => {
  return apiRequest(`projects/${id}/save`, {
    method: 'POST'
  })
}

export const toggleProjectEditing = async (id: string, isEditing: boolean): Promise<void> => {
  return apiRequest(`projects/${id}/toggle-editing`, {
    method: 'POST',
    body: {
      isEditing
    }
  })
}

export const getParsedDeck = (deck: DeckFormatted): DeckParsed => {
  const data = JSON.parse(deck.data)

  const output = {
    ...deck,
    ...data
  }

  delete output.data

  return output
}

// TODO Cleanup types
const getParsedOverview = (project: ProjectFormatted): any => {
  if (!project.overview) {
    return {} as any
  }

  return JSON.parse(project.overview) as any
}

const getParsedTheme = (project: ProjectFormatted): ProjectTheme => {
  if (!project.theme) {
    return {} as ProjectTheme
  }

  return JSON.parse(project.theme) as ProjectTheme
}

export const getProjectParsed = (project?: ProjectFormatted): Project | undefined => {
  if (project === undefined) {
    return undefined
  }

  return {
    ...project,
    theme: getParsedTheme(project),
    overview: getParsedOverview(project),
    decks: project.decks.map(d => getParsedDeck(d))
  }
}

export const getProjectBySlug = async (slug: string): Promise<Project | undefined> => {
  const rawProject = await apiRequest(`projects/slug/${slug}`)

  if (!rawProject) {
    return undefined
  }

  return getProjectParsed(rawProject) as Project
}

export const getProjectById = async (id: string): Promise<Project | undefined> => {
  const rawProject = await apiRequest(`projects/${id}`)

  if (!rawProject) {
    return undefined
  }

  return getProjectParsed(rawProject) as Project
}

export const addProjectMedia = async (projectId: string, mediaIds: string[]) => {
  return apiRequest(`projects/${projectId}/media`, {
    method: 'PUT',
    body: {
      mediaIds
    }
  })
}

export const deleteProject = async (projectId: string) => {
  return apiRequest(`projects/${projectId}`, {
    method: 'DELETE'
  })
}
