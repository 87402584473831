import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

// TODO: Find better place for default spacing scale.
const spacing = {
  '0': 0,
  'sm': '1rem',
  'md': '2rem',
  'lg': '4rem',
  'auto': 'auto'
}

export const Component = styled(motion.div) <any>`
  position: relative;
  padding: 4px;
  gap: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column; // In Areas only Columns are allowed
  justify-content: center;
  text-align: center;
  flex: 1 1 auto;
  box-sizing: border-box;

  ${props => props.$isEditable && css`
    outline: ${(props.$isActive ? '2px solid black' : '1px dashed rgba(0,0,0,0.2)')};
    cursor: pointer;

    > * {
      pointer-events: none;
    }

  `}

  // TODO make a util function for background settings
  ${props => props.background?.color && css`
    background-color: ${getProjectThemeColor(props.theme.colors, (props.background.color))};
  `}

  ${props => props.colors?.defaultColor && css`
    color: ${getProjectThemeColor(props.theme.colors, (props.colors.defaultColor))};
  `}

  ${props => props.colors?.accentColor && css`
    .accent {
      color: ${getProjectThemeColor(props.theme.colors, (props.colors.accentColor))};
    }
  `}


  ${props => props.background?.image && css`
    background-image: url('${props.background.image}');
    background-size: ${props.background.size};
    background-position: ${props.background.position};
    background-repeat: ${props.background.repeat};
  `}

  // TODO make a util function for layout settings
  ${props => props.layout && css`
    flex-direction: ${props.layout?.direction === 'x' ? 'row' : 'column'};
    justify-content: ${props.layout?.valign === 'top' ? 'flex-start' : (props.layout.valign === 'bottom' ? 'flex-end' : 'center')};
    align-items: ${props.layout?.halign === 'left' ? 'flex-start' : (props.layout.halign === 'right' ? 'flex-end' : 'center')};
    text-align:  ${props.layout?.halign === 'center' ? 'center' : 'left'};
  `}

  // TODO make a util function for spacing settings
  ${props => props.spacing && css`
    padding: ${spacing[props.spacing?.padding] || 0};
    gap:${spacing[props.spacing?.padding] || 0};
    margin: ${spacing[props.spacing?.margin] || 0};
    grid-column: span ${props.spacing?.width};
    grid-row: span ${props.spacing?.height};
  `}
`

export default Component
