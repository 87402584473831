import VideoPlayer from '@pidk/common/src/components/VideoPlayer'
import type { ReactNode } from 'react'

import Image from '../components/Blocks/Image'
import type { IPopup } from '../components/common/Popup'
import { Popup } from '../components/common/Popup'
import { RendererView } from '../types/slide'
import useRendererContext from './useRendererContext'

export interface IBlockAction {
  type: 'link' | 'video' | 'audio' | 'image' | 'slide' | 'popup'
  url?: string
  youtube?: string
  slide?: {
    deck: string
    slide: string
  }
  popup?: IPopup
  image?: string
}

interface IUseBlockAction {
  onPopup: (type: string, component: ReactNode, timeout?: number, closable?: boolean) => void
}

const useBlockAction = ({ onPopup }: IUseBlockAction) => {
  const { onNavigate } = useRendererContext()

  return (action: IBlockAction) => () => {
    const {
      type,
      slide,
      url,
      youtube,
      popup,
      image
    } = action

    if (slide && type === 'slide') {
      return onNavigate(RendererView.DECK, slide.deck, slide.slide)
    }

    if (type === 'link' && url) {
      return window.open(url, '_blank')
    }

    if (type === 'video' && (youtube || url)) {
      return onPopup('video',
        <VideoPlayer
          source={url}
          youtube={youtube}
          controls
          autoPlay
        />
      )
    }

    if (type === 'popup') {
      return onPopup('text-image',
        <Popup {...popup} />
      )
    }

    if (type === 'image') {
      return onPopup('image',
        <Image image={image} />
      )
    }
  }
}

export default useBlockAction
