import type { IFieldSchema } from './../../types/fields'
import { FieldType } from './../../types/fields'

const TransitionPropSchema: IFieldSchema = {
  name: 'Transition',
  key: 'transition',
  defaultFieldValues: {
    transition: {
      preset: 'fadeUp',
      delay: 3750
    }
  },
  fields: [
    {
      key: 'transition',
      type: FieldType.GROUP,
      label: 'Transition',
      fields: [
        {
          key: 'preset',
          label: 'Preset',
          type: FieldType.CHOICE,
          options: [
            {
              value: '',
              label: 'Default'
            },
            {
              value: 'none',
              label: 'None'
            },
            {
              value: 'fadeIn',
              label: 'Fade in'
            },
            {
              value: 'fadeUp',
              label: 'Fade up'
            },
            {
              value: 'fadeDown',
              label: 'Fade down'
            },
            {
              value: 'fadeLeft',
              label: 'Fade left'
            },
            {
              value: 'fadeRight',
              label: 'Fade right'
            }
          ]
        },
        {
          key: 'delay',
          label: 'Delay',
          type: FieldType.NUMBER,
          instructions: 'In milliseconds (1 sec = 1000 ms)',
          step: 1,
          min: 0,
          max: 2000,
          appendix: 'ms',
          conditional: [{
            field: 'preset',
            value: '',
            operator: '!='
          }, {
            field: 'preset',
            value: 'none',
            operator: '!='
          }]
        }
      ]
    }
  ]
}

export default TransitionPropSchema
