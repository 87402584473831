import SFX from '@pidk/common/src/sfx'
import { useAnimation } from 'framer-motion'
import { useEffect, useRef } from 'react'
import useSound from 'use-sound'

import { DragItem } from './../Connect.styled'

const DragableItem = ({ id, drag, index, value, children, constraints, actions }) => {

  const ref = useRef(null)
  const itemControls = useAnimation()
  const [errorSFX] = useSound(SFX.negativeDongs)
  const [correctSFX] = useSound(SFX.correctBell, {
    volume: 0.1
  })
  const [hoverSFX] = useSound(SFX.click, {
    volume: 0.1
  })

  // Animate on initial load
  useEffect(() => {
    itemControls.start({
      scale: [1.1, 1],
      rotateZ: [5, -5, (Math.random() < 0.5 ? -1 : 1)], // Randomize boolean
      y: [0, -5, 0],
      transition: {
        duration: 0.4,
        delay: 0.1 * index
      }
    })
  }, [])

  const animateHover = () => {
    hoverSFX()
    itemControls.start({
      scale: 1.1,
      rotateZ: (Math.random() < 0.5 ? -5 : 5),
      transition: {
        duration: 0.2
      }
    })
  }

  const animateError = () => {

    errorSFX()
    itemControls.start({
      rotateZ: [-10, 5],
      zIndex: 40,
      transition: {
        duration: 0.2,
        yoyo: 3,
        ease: 'bounceInOut'
      }
    }).then(() => {
      itemControls.start({
        x: 0,
        y: 0,
        scale: 1,
        rotateZ: (Math.random() < 0.5 ? -1 : 1)
      })
    })
  }

  useEffect(() => {
    const handleReset = () => {
      itemControls.start({
        scale: [1.1, 1],
        rotateZ: [5, -5, (Math.random() < 0.5 ? -1 : 1)], // Randomize boolean
        y: [0, -5, 0],
        x: [0, 0, 0],
        transition: {
          duration: 0.3,
          delay: 0.1 * index
        }
      })
    }

    window.addEventListener('resize', handleReset)
    return () => {
      window.removeEventListener('resize', handleReset)
    }
  }, [])

  return (
    <DragItem
      key={id}
      ref={ref}
      drag={drag}
      animate={itemControls}
      dragConstraints={constraints}
      onDragStart={() => { actions.setDragging(true, ref) }}
      onDragEnd={() => { actions.onDragEnd(value, () => animateError(), (correct) => (correct ? correctSFX() : hoverSFX())) }}
      onDrag={(e: DragEvent) => { actions.onDrag(e, () => animateHover()) }}
      whileDrag={{ zIndex: 40, position: 'relative' }}
      dragMomentum={false}
    >
      {children}
    </DragItem>
  )
}

export default DragableItem
