import KonvaWhiteboard from '@pidk/common/src/components/Whiteboard'
import { FieldType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import styled, { useTheme } from 'styled-components'

import useLessonState from './../../../hooks/useLessonState'
import useRendererContext from './../../../hooks/useRendererContext'

type BlockComponent = React.FC<IWhiteboard> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

interface IWhiteboard {
  backgroundColor?: string
  strokeColor?: string
  title?: string
}

const Component = styled.div<any>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0;
    text-align: center;
  }
`

const Whiteboard: BlockComponent = ({
  backgroundColor = 'transparent',
  strokeColor,
  title
}: IWhiteboard) => {

  // TODO; somehow the types aren't working when using useTheme
  const theme:any = useTheme()
  const { currentSlideState, storeSlideState } = useLessonState()
  const { currentDeckId, currentSlideId } = useRendererContext()

  return (
    <Component>
      <KonvaWhiteboard
        backgroundColor={backgroundColor}
        strokeColor={getProjectThemeColor(theme?.colors, strokeColor || 'primary')}
        onChange={(data) => storeSlideState(currentDeckId, currentSlideId, data)}
        initialState={currentSlideState(currentDeckId, currentSlideId)}
      />

      {title &&
        <h2>{title}</h2>}
    </Component>
  )
}

Whiteboard.schema = {
  name: 'Whiteboard',
  key: 'whiteboard',
  defaultFieldValues: {
    title: ''
  },
  fields: [
    {
      key: 'title',
      type: FieldType.TEXT,
      label: 'Title'
    },
    {
      key: 'strokeColor',
      type: FieldType.COLOR,
      label: 'Stroke Color'
    }
  ]
}

Whiteboard.Styled = Component

export default Whiteboard
