import { CheckIcon, Cross1Icon } from '@pidk/common/src/components/Icons'
import type { ReactNode } from 'react'

import Component, { Image } from './../../../common/Option'

interface IOption {
  children: ReactNode
  onClick?: () => void
  isCorrect?: boolean
  isSelected: boolean
  votes?: number
  label?: string
  state: 'init' | 'selected' | 'results' | 'voting' | 'feedback' // TODO: cleanup
}

const Option = ({
  onClick,
  children,
  isCorrect,
  isSelected,
  votes,
  label,
  state
}: IOption) => {

  return (
    <Component
      onClick={onClick}
      $isCorrect={isCorrect}
      $isSelected={isSelected}
      $state={state}
      disabled={state !== 'init' && state !== 'selected'}
    >
      {children}

      <strong className='badge'>
        {state === 'results' && isCorrect !== null && (isCorrect ? <CheckIcon /> : <Cross1Icon />) || label}
      </strong>

      {votes &&
        <strong>{votes}</strong>}

    </Component>
  )
}

Option.Styled = Component

export {
  Image
}

export default Option
