/* eslint-disable react/no-unused-prop-types */
import type { MediaFormatted } from '@pidk/api/src/types/api'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import useProjectMediaMap from '@pidk/renderer/src/hooks/useProjectMediaMap'
import { useEffect, useState } from 'react'
import type { ReactNode } from 'react'

import { Actions, ActionButton } from './../../common/Actions'
import type { IFeedback } from './../../common/Feedback'
import Feedback from './../../common/Feedback'
import Grid from './components/Grid'
import { Base } from './Memory.styled'
import useMemory from './useMemory'

/*
Memory TODO:
- ✅ Cleanup and add correct types
- ✅ Seperate Memory game functions in hook
- ⏰ Add an option to display in fixed column amount (dynamic column count based on items)
- Maybe improve soundFX
- Add timer
- ⏰ Save progress in lessonState
- ✅ Restart Interaction once done
*/

/*
TODO: Themefied styling options:
  - default backImage?
  - SoundFX (correct, wrong)
*/

type BlockComponent = React.FC<ISort> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

interface IItem {
  label?: string
  image?: string
}
interface IOption {
  id: string
  label: string
  base?: IItem
  match?: IItem
}

interface ISort {
  id: string
  type?: 'text' | 'image'
  backImage: string
  columns: number
  options: IOption[]
  feedback?: IFeedback
  onPopup?: (type: string, component: ReactNode, timeout, closable) => void
}

interface IMemory {
  id: string
  parentId: string
  image?: MediaFormatted
  label?: string
}

const Memory: BlockComponent = ({
  id, backImage, columns, options, feedback, onPopup
}: ISort) => {

  const images = useProjectMediaMap()
  const [list, setList] = useState<IMemory[]>([])

  const onShowFeedback = () => {
    return onPopup('dialog', <Feedback
      title={feedback?.title}
      content={feedback?.content}
      image={feedback?.image}
                             />, null, true)
  }

  const { items, resetItems, visibleItems, finishedItems, handleItemClick } = useMemory({ id, list, onDone: () => onShowFeedback() })

  useEffect(() => {

    const cards = []

    options.map((o) => {

      cards.push({
        id: o.id + '-' + 1,
        parentId: o.id,
        image: images[o.base?.image],
        label: o.base?.label
      })

      cards.push({
        id: o.id + '-' + 2,
        parentId: o.id,
        image: images[o.match?.image],
        label: o.match?.label
      })

    })

    setList(cards)

  }, [options])

  return (
    <Base key={id}>
      <Grid
        columnCount={columns}
        defaultImage={images[backImage]}
        items={items}
        finishedItems={finishedItems}
        visibleItems={visibleItems}
        handleItemClick={handleItemClick}
      />
      {finishedItems.length === list.length && (
        <Actions>
          <ActionButton
            as='button'
            onClick={() => resetItems()}
          >Opnieuw spelen
          </ActionButton>
        </Actions>
      )}
    </Base>
  )
}

Memory.schema = {
  name: 'Memory',
  key: 'memory',
  defaultFieldValues: {
    type: 'image',
    options: [],
    columns: 4
  },
  fields: [
    {
      key: 'type',
      type: FieldType.CHOICE,
      label: 'Type',
      options: [
        {
          value: 'text',
          label: 'Text'
        },
        {
          value: 'image',
          label: 'Image'
        }
      ]
    },
    {
      key: 'backImage',
      type: FieldType.MEDIA,
      label: 'Backside Image',
      instructions: 'Add an image to display as default.',
      maxFiles: 1,
      mediaType: MediaType.IMAGE
    },
    {
      key: 'columns',
      type: FieldType.RANGE,
      label: 'Columns',
      min: 1,
      max: 7,
      step: 1
    },
    {
      key: 'options',
      type: FieldType.REPEATER,
      sortable: false,
      label: 'Opties',
      primaryKey: 'label',
      button: 'Add option',
      collapsible: false,
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label'
        }, {
          key: 'base',
          type: FieldType.GROUP,
          label: 'Base',
          primaryKey: 'label',
          collapsible: false,
          fields: [
            {
              key: 'label',
              type: FieldType.TEXT,
              label: 'Label',
              conditional: [{
                field: 'type',
                operator: '==',
                value: 'text'
              }]
            }, {
              key: 'image',
              type: FieldType.MEDIA,
              label: 'Image',
              maxFiles: 1,
              mediaType: MediaType.IMAGE
            }
          ]
        }, {
          key: 'match',
          type: FieldType.GROUP,
          label: 'Match',
          collapsible: false,
          fields: [
            {
              key: 'label',
              type: FieldType.TEXT,
              label: 'Label',
              conditional: [{
                field: 'type',
                operator: '==',
                value: 'text'
              }]
            }, {
              key: 'image',
              type: FieldType.MEDIA,
              label: 'Image',
              maxFiles: 1,
              mediaType: MediaType.IMAGE
            }
          ]
        }
      ]
    },
    {
      key: 'feedback',
      type: FieldType.GROUP,
      label: 'Feedback',
      primaryKey: 'content',
      fields: [...Feedback.schema.fields]
    }
  ]
}

Memory.Styled = Base

export default Memory
