import Button from './Button'
import Checkout from './Checkout'
import Choice from './Choice'
import Connect from './Connect'
import Dragzone from './Dragzone'
import Hotspots from './Hotspots'
import Image from './Image'
import LinkGrid from './LinkGrid'
import Memory from './Memory'
import Reorder from './Reorder'
import RichText from './RichText'
import Video from './Video'
import Whiteboard from './Whiteboard'

const blockComponent = {
  'text': RichText,
  'choice': Choice,
  'image': Image,
  'whiteboard': Whiteboard,
  'button': Button,
  'video': Video,
  'sort': Dragzone,
  'reorder': Reorder,
  'connect': Connect,
  'memory': Memory,
  'checkout': Checkout,
  'link-grid': LinkGrid,
  'hotspots': Hotspots
}

export const getBlockComponent = (type) => {
  const Block = blockComponent[type] || null

  return Block
}

export const getAvailableBlocks = () => {
  const blocks = Object.keys(blockComponent).map(key => {
    return {
      id: blockComponent[key].schema.key,
      name: blockComponent[key].schema.name
    }
  })

  return blocks
}

export const getBlockSchema = (type) => {
  const Block = blockComponent[type] || null

  if (process.env.NODE_ENV === 'development') {
    if (!Block) {
      throw new Error(`Could not find Slide component for type ${type}`)
    }

    if (!Block.schema) {
      throw new Error(`Could not find Slide schema for type ${type}`)
    }
  }

  return Block.schema
}
