/* eslint-disable */
import type { ReactNode, RefObject } from 'react'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import { Background } from '@pidk/compose/src/schemas/properties'
import useProjectMediaMap from '@pidk/renderer/src/hooks/useProjectMediaMap'
import { Base, Dropzone, Dragzone, Title } from './Dragzone.styled'
import type { IFeedback } from './../../common/Feedback'
import Feedback from './../../common/Feedback'

import useSort from './useSort'
import DraggableItem from './../Connect/components/DraggableItem'
import Option from './../Connect/components/Option'
import {
  Grid,
  Label
} from './../Connect/Connect.styled'

import { Actions, ActionButton } from './../../common/Actions'

type BlockComponent = React.FC<ISort> & {
  schema: IFieldSchema
  Styled?: any
}
interface IOption {
  id: string
  label: string
  image?: string
}

interface IZone {
  id: string
  label: string
  align?: string
  image?: string
  options: IOption[]
  background: any
}

interface ISort {
  id: string
  layout: 'horizontal' | 'vertical'
  zones: IZone[]
  correct: boolean
  screenRef: RefObject<HTMLDivElement>
  feedback?: IFeedback
  isEditable?: boolean
  onPopup?: (type: string, component: ReactNode, timeout?: number, closable?: boolean) => void
}

const Sort: BlockComponent = ({
  id,
  layout,
  correct,
  zones,
  isEditable,
  feedback,
  onPopup
}: ISort) => {

  const images = useProjectMediaMap()

  const onShowFeedback = () => {
    return onPopup('dialog', <Feedback
      title={feedback?.title}
      content={feedback?.content}
      image={feedback?.image}
    />, null, true)
  }

  // eslint-disable-next-line
  const { state, options, dragOverZone, refs, selection, actions, isDragging } = useSort({ correct, preview: isEditable, id, zones, onDone: () => onShowFeedback() })

  return (
    <Base>
      <Grid
        ref={refs['constraintsRef']}
        $count={zones?.length}
        $isDropzone={true}
        $layout={(layout === 'vertical' ? 'horizontal' : 'vertical')}> {/* TODO Fix this ugly shit */}

        {zones && zones.map((c, i) => {

          const droppedSelection = selection[c.id]

          const backgroundProperties = {
            ...c.background,
            image: images[c?.background?.image]?.url
          }

          return (

            <Dropzone
              key={`dropzone-${c.id}`}
              ref={refs['dropzonesRef'][c.id]}
              $isActive={dragOverZone === c.id && isDragging == true}
              $isDone={selection[c.id] || false}
              $align={c.align}
              background={backgroundProperties}
            >
              <Title className="accent">{c.label}</Title>
              <Grid
                $wrapped={true}
              >
                {droppedSelection?.length > 0 && droppedSelection.map((s, i) => (
                  <DraggableItem
                    key={`draggable-${s.id}`}
                    id={`draggable-${s.id}`}
                    index={i}
                    constraints={refs['constraintsRef']}
                    actions={actions}
                    value={s}
                    drag={false}
                  >
                    <Option
                      key={`option-${s.id}`}
                      state='selected'
                    >
                      {s.image && (
                        <figure className='background'><img src={images[s.image]?.url} /></figure>
                      )}
                      <Label>{s.label}</Label>
                    </Option>
                  </DraggableItem>
                ))}
              </Grid>
            </Dropzone>
          )
        }
        )}
      </Grid>

      <Actions
        key="actions"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
        style={options?.filter(o => o !== null).length > 0 && {
          bottom: '7vh'
        }}
      >
        {options?.filter(o => o !== null).length > 0 && (
          <Grid
            $layout='vertical'
          >
            {options && options?.filter(o => o !== null).slice(0, 5).map((o, i) => {
              return (
                <Dragzone
                  key={`dragzone-${i}`}
                >
                  {o !== null && (
                    <DraggableItem
                      key={`draggable-${o.id}`}
                      id={`draggable-${o.id}`}
                      index={i}
                      constraints={refs['constraintsRef']}
                      actions={actions}
                      value={o}
                      drag
                    >
                      <Option
                        key={`option-${o.id}`}
                        isSelected={false}
                        state={state}
                      >
                        {o.image && (
                          <figure className='background'><img src={images[o.image]?.url} /></figure>
                        )}
                        <Label>{o.label}</Label>
                      </Option>
                    </DraggableItem>
                  )}
                </Dragzone>
              )

            })}
          </Grid>
        )}
        {options?.filter(o => o !== null).length === 0 && (
          <ActionButton
            as='button'
            onClick={() => actions.reset()}
          >Opnieuw spelen
          </ActionButton>
        )}
      </Actions>
    </Base>
  )
}

Sort.schema = {
  name: 'Sort',
  key: 'sort',
  defaultFieldValues: {
    layout: 'horizontal'
  },
  fields: [
    {
      key: 'layout',
      type: FieldType.CHOICE,
      label: 'Layout',
      component: 'button',
      options: [
        {
          value: 'horizontal',
          label: 'Horizontal'
        },
        {
          value: 'vertical',
          label: 'Vertical'
        }
      ]
    },
    {
      key: 'correct',
      type: FieldType.BOOLEAN,
      label: 'Correct zone',
      instructions: 'Only allow options to be sorted to the correct zone.'
    },
    {
      key: 'zones',
      type: FieldType.REPEATER,
      label: 'Zones',
      primaryKey: 'label',
      button: 'Add zone',
      collapsible: false,
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label'
        },
        {
          key: 'align',
          type: FieldType.CHOICE,
          component: 'button',
          label: 'Alignment label',
          options: [
            {
              value: 'top left',
              label: 'Left'
            },
            {
              value: 'top center',
              label: 'Center'
            },
            {
              value: 'top right',
              label: 'Right'
            }
          ]
        },
        ...Background.fields,
        {
          key: 'options',
          type: FieldType.REPEATER,
          label: 'Options',
          sortable: false,
          primaryKey: 'label',
          collapsible: false,
          button: 'Add option',
          fields: [
            {
              key: 'label',
              type: FieldType.TEXT,
              label: 'Label'
            },
            {
              key: 'image',
              label: 'Image',
              type: FieldType.MEDIA,
              maxFiles: 1,
              mediaType: MediaType.IMAGE
            }
          ]
        }
      ]
    },
    {
      key: 'feedback',
      type: FieldType.GROUP,
      label: 'Feedback',
      primaryKey: 'content',
      fields: [...Feedback.schema.fields]
    }
  ]
}

Sort.Styled = Base

export default Sort
