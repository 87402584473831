import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Base = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
`

export const Card = styled(motion.button)`
      cursor: pointer;
      width: 12vw;
      border-radius: 4px;
      border: 0;
      padding: 0;
      position: relative;
      background: none;
      font-family: ${({ theme }) => theme.fonts?.fontFamily?.buttons};
      font-weight: bold;

      &::before {
        content: '';
        padding-bottom: 100%;
        display: block;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        border-radius: 4px;
        transition: transform 1s;
        transform-style: preserve-3d;
      }

      .label {
        display: block;
        font-size: ${({ theme }) => theme.fonts.fontSize['base']};
        font-size: clamp(0.8em, 1.75vw,  24px);
        line-height: 1.1;
      }

      &.is-revealed .content {
        transform: rotateY( 180deg );
        transition: transform 0.5s;
      }

      .front,
      .back {
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background: white;
        line-height: 300px;
        color: #03446A;
        text-align: center;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        backface-visibility: hidden;
        overflow: hidden;
      }

      .front {
        transform: rotateY( 180deg );
        ${({ theme }) => css`

          background-color: ${getProjectThemeColor(theme.colors, (theme.memory?.front?.backgroundColor || 'white'))};
          color: ${getProjectThemeColor(theme.colors, (theme.memory?.front?.color || 'secondary'))};

          `}

      }

      .back {


        ${({ theme }) => css`

          background-color: ${getProjectThemeColor(theme.colors, (theme.memory?.back?.backgroundColor || 'white'))};
          color: ${getProjectThemeColor(theme.colors, (theme.memory?.back?.color || 'secondary'))};

        `}
      }

`

export const Component = styled.div<any>`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  grid-template-columns: ${({ $columnCount }) => `repeat(${($columnCount ? $columnCount : 4)}, minmax(10%, 12vw))`};

  ${Card} {
      width: 100%;
  }
`
