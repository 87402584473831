/* eslint-disable */
import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import generateClamp from '@pidk/common/src/utils/clamp'
import styled from 'styled-components'
import { borderRadii, getBorderRadius } from '@pidk/renderer/src/theme/default'

export const Base = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div<any>`
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: fit-content;
`

export const BackgroundImage = styled.img<any>`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
`

export const Hotspot = styled.div<{
  top: string
  left: string
  width: string
  height: string
  showBackground: boolean
}>`
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ showBackground }) => showBackground ? `rgba(0, 0, 255, .3)` : 'transparent'};

  &:hover {
    opacity: 1;
  }
`

export const Marker = styled.div<any>`
  position: absolute;
  display: flex; 
  align-items: center;
  justify-content: center; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.marker?.backgroundColor || theme.option?.badge?.backgroundColor)};
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.marker?.color || theme.option?.badge?.color)};
  font-size: ${generateClamp(14, 20, 320, 1920)};
  // line-height: 1.2;
  min-width: 2rem;
  min-height: 2rem;
  font-family: ${props => props.theme.fonts.fontFamily['buttons']};
  font-weight: bold;
  padding: ${({ theme }) => theme.button.base.borderRadius === borderRadii[borderRadii.length - 1] ? '0 0.5rem' : ' 0 0.5rem'};
  border-radius: ${({ theme }) => (theme.button.base.borderRadius && getBorderRadius(theme.button.base.borderRadius) || getBorderRadius('sm'))};
  box-shadow: rgba(0, 0, 0, 0.15) 0.1rem 0.1rem 1rem;
`