import { ChevronDownIcon, ChevronUpIcon, ChevronRightIcon } from '@pidk/common/src/components/Icons'
import transitions from '@pidk/common/src/lib/transitions'
import { motion, AnimatePresence } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import { Button as StyledButton } from './../../Slide/Slide.styled'
// TODO Cleanup remove this find cleaner way

const Dropdown = styled.div`
  margin-bottom: 1rem;
  position: relative;
`
const DropdownList = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 80%;
  left: -10%;
  background-color: ${props => props.theme.colors.white};
  z-index: 20;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows['2xl']};
  overflow: hidden;
  width: 140%;
  max-height: 300px;
  overflow-y: auto;
`

const DropdownListItem = styled.li`
  width: 100%;
  & + & {
    border-top: 1px solid ${props => props.theme.colors.light}
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-radius: 0;
    padding: 1rem;
    width: 100%;
    border: 0;
    font-size: ${({ theme }) => theme.fonts.fontSize['base']};
    font-family: ${props => props.theme.fonts.fontFamily['buttons']};
    text-align: left;

    &:hover {
      background-color: ${props => props.theme.colors.light};
    }

    svg {
      margin-left: 8px;
      flex-shrink: 0;
    }
  }
`
interface IButtonPopoverItem {
  label: string
  url: string
}
interface IButtonPopover {
  label: string
  items: IButtonPopoverItem[]
  handleItemClick?: (payload: IButtonPopoverItem) => void
}

export const ButtonPopover = ({ label, items, handleItemClick }: IButtonPopover) => {
  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)
  const [collapsed, setCollapsed] = useState<boolean>(true)

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setCollapsed(true)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [collapsed])

  return (
    <Dropdown>
      <StyledButton
        ref={buttonRef}
        onClick={() => setCollapsed(!collapsed)}
      >
        {label}
        {collapsed ? (
          <ChevronDownIcon
            width={24}
            height={24}
          />
        ) : (
          <ChevronUpIcon
            width={24}
            height={24}
          />
        )}
      </StyledButton>
      <AnimatePresence>
        {!collapsed && (
          <DropdownList
            key='dropdown'
            ref={dropdownRef}
            initial={transitions.fadeUpAndDown.initial}
            animate={transitions.fadeUpAndDown.animate}
            exit={transitions.fadeUpAndDown.exit}
          >
            {items.map((item, i) => {
              return (
                <DropdownListItem key={'dropdown' + i}>
                  <button onClick={() => handleItemClick(item)}>
                    {item.label}
                    <ChevronRightIcon />
                  </button>
                </DropdownListItem>
              )
            })}
          </DropdownList>
        )}
      </AnimatePresence>
    </Dropdown>
  )
}

export default ButtonPopover
