import { EventType } from '@pidk/api/src/types/api'
import ActionsPropSchema from '@pidk/compose/src/schemas/properties/actions'
import { FieldType } from '@pidk/compose/src/types/fields'
import type { FieldChoice, IFieldSchema } from '@pidk/compose/src/types/fields'
import type { ReactNode } from 'react'

import type { IBlockAction } from '../../../hooks/useBlockAction'
import useBlockAction from '../../../hooks/useBlockAction'
import useRendererContext from '../../../hooks/useRendererContext'
import ButtonPopover from '../../common/ButtonPopover'
import { Button as StyledButton } from './../../Slide/Slide.styled'

type BlockComponent = React.FC<IButton> & {
  schema: IFieldSchema
  Styled?: any
}

// type: 'link' | 'video' | 'audio' | 'image' | 'slide' | 'popup'
// url?: string
// youtube?: string
// slide?: {
//   deck: string
//   slide: string
// }
// popup?: IPopup
// image?: string

interface IButton extends Omit<IBlockAction, 'type'>  {
  label: string
  type: IBlockAction['type'] | 'popover'
  items?: { label: string; url: string }[]
  onPopup?: (type: string, component: ReactNode, timeout?: number, closable?: boolean) => void
}

const Button: BlockComponent = ({
  label,
  type,
  url,
  youtube,
  slide,
  items,
  popup,
  image,
  onPopup
}: IButton) => {
  const handleAction = useBlockAction({ onPopup })
  const { onScopedEventCreate } = useRendererContext()

  const handleItemClick = async (item: { label: string; url: string }) => {
    if (!item.url) {
      return
    }

    if (item.url.includes('.pdf') || item.url.includes('amazonaws')) {
      await onScopedEventCreate({
        type: EventType.PROJECT_DOWNLOAD_FILE,
        meta: {
          fileName: item.label
        }
      })
    }

    window.open(item.url, '_blank')
  }

  if (type === 'popover') {
    return (
      <ButtonPopover
        label={label}
        items={items}
        handleItemClick={(p) => handleItemClick(p)}
      />
    )
  }

  return (
    <StyledButton
      as={type === 'link' ? 'a' : 'button'}
      href={type === 'link' ? url : false}
      onClick={handleAction({ type, slide, url, youtube, popup, image })}
      target={type === 'link' ? url : false}
    >
      {label}
    </StyledButton>
  )
}

const ExtendedActionPropSchema = {
  ...ActionsPropSchema, // Clone the existing object

  fields: ActionsPropSchema.fields.map((f) => {
    if (f.key === 'type') {
      const field = f as FieldChoice
      return {
        ...field, // Clone the "type" field
        options: [
          ...field.options, // Clone the existing options
          {
            value: 'popover',
            label: 'Popover'
          }
        ]
      }
    }
    return f
  })
}

Button.schema = {
  name: 'Button',
  key: 'button',
  defaultFieldValues: {
    label: 'Button',
    type: 'link'
  },
  fields: [
    {
      key: 'label',
      type: FieldType.TEXT,
      label: 'Label'
    },
    ...ExtendedActionPropSchema.fields,
    {
      key: 'items',
      type: FieldType.REPEATER,
      label: 'Items',
      primaryKey: 'label',
      collapsible: false,
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label'
        }, {
          key: 'url',
          type: FieldType.TEXT,
          label: 'Url'
        }
      ],
      conditional: [{
        field: 'type',
        operator: '==',
        value: 'popover'
      }]
    }
  ]
}

Button.Styled = StyledButton

export default Button
