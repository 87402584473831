/* eslint-disable */
import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Image from '@pidk/renderer/src/components/Blocks/Image'
import { ComponentCSSTheme } from '@pidk/renderer/src/types/slide'
import generateClamp from '@pidk/common/src/utils/clamp'
import { borderRadii, getBorderRadius } from '@pidk/renderer/src/theme/default'

const baseTheme: ComponentCSSTheme = {
  base: {
    borderRadius: getBorderRadius('sm'),
    backgroundColor: 'white',
    color: 'primary',
    padding: '2rem',
    fontSize: '2xl',
    fontFamily: 'buttons'
  },
  hover: {
    backgroundColor: 'light',
    color: 'black'
  }
}

export const Card = styled.button<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  overflow: hidden;

  font-family: ${({ theme }) => theme.fonts.fontFamily[baseTheme.base.fontFamily]};
  font-size: ${generateClamp(14, 20, 320, 1920)};

  background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.card.base.backgroundColor || baseTheme.base.backgroundColor)};
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.card.base.color || baseTheme.base.color)};
  border-radius: ${({ theme }) => (theme.button.base.borderRadius && getBorderRadius(theme.button.base.borderRadius) || baseTheme.base.borderRadius)};

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => theme.button.base.borderRadius === borderRadii[borderRadii.length - 1] ? '0 2rem' : ' 0 1rem'};
    height: 4rem;
    box-sizing: border-box;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }

  ${(props) => props.$hasImage && css`
    flex-direction: column;
    border-radius: ${({ theme }) => (theme.card.base.borderRadius && getBorderRadius(theme.card.base.borderRadius) || baseTheme.base.borderRadius)};
    max-width: 30rem;

    > div {
     padding: ${({ theme }) => theme.card.base.borderRadius === borderRadii[borderRadii.length - 1] ? '0 2rem' : ' 0 1rem'};
    }

    ${Image.Styled} {
      aspect-ratio: 2/1;
      width: 100%;
    }
  `}

  svg {
    flex-shrink: 0;
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.card.hover.backgroundColor || baseTheme.hover.backgroundColor)};
    color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.card.hover.color || baseTheme.hover.color)};
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    transform: translateX(-2px) translateY(-2px) scale(1.01);
    transition: all 175ms ease-in-out;
  }
`

export const GridItem = styled(motion.li)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

export const Base = styled.ul<any>`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  grid-template-columns: ${({ $columnCount }) => `repeat(${($columnCount ? $columnCount : 4)}, minmax(10%, 30rem))`};

  ${({ $columnCount }) => {

    if ($columnCount > 2) {
      return css`

        ${Card} {
          //font-size: ${({ theme }) => `${theme.fonts.fontSize['xs']}`};
          div {
            font-size: 0.9em;
          }
          svg {
            display: none;
          }
        }

      `
    }

  }}
`
