import { areaContentTransition } from '@pidk/renderer/src/utils/transitions'
import { AnimatePresence } from 'framer-motion'

import useProjectMedia from '../../hooks/useProjectMedia'
import Component from './Area.styled'

const Area = ({
  children,
  tabIndex,
  onClick,
  isActive,
  isEditable,
  properties
}) => {

  const [blockImage] = useProjectMedia([properties?.background?.image])

  const backgroundProperties = {
    ...properties?.background,
    image: blockImage?.url
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <Component
        tabIndex={tabIndex}
        onClick={() => onClick()}
        $isActive={isActive}
        $isEditable={isEditable}
        layout={properties?.layout}
        background={backgroundProperties}
        colors={properties?.colors}
        spacing={properties?.spacing}
        {...areaContentTransition({ ...properties.transition })}
      >
        {children}
      </Component>
    </AnimatePresence>
  )
}

Area.Styled = Component

export default Area
