// eslint-disable
import { shuffle as shuffleArray } from '@pidk/common/src/utils/arrays'
import * as React from 'react'

const mapOptions = (zones, shuffle = false) => {

  if(!zones) return

  const options = []

  // Extract all the available options in single list
  zones.map(z => {

    z?.options?.map((o, i) => {
        options.push({ ...o, zone: z.id, id: z.id + '-' + i })
      })
  })

  // Shuffle the original options
  const shuffledOptions = (shuffle ? shuffleArray(options) : options)

  // TODO fix so original option is never in same place

  return shuffledOptions

}
interface IUseSort {
  correct: boolean
  preview: boolean
  id: string
  zones: any[]
  onDone: () => void
}

type SortState = 'init' | 'selected' | 'results' | 'voting' | 'feedback'

interface ISortReturn {
  isDragging: boolean
  dragOverZone: string
  refs: any
  // refs: {
  //   constraintsRef: React.RefObject<HTMLElement>,
  //   dropzonesRef: React.RefObject<HTMLElement>[],
  //   dragRef: React.RefObject<HTMLElement>,
  // }
  state: SortState
  selection: {}
  options: any[]
  controls: {}
  actions: {
    // init: (id: string, motionControls: AnimationControls ) => void
    handleAnswer: (start: string, zone: string) => void
    reset: () => void
    setDragging: (boolean: boolean, option: any) => void
    onDrag: (event: DragEvent, callback: void) => void
    onDragEnd: (option: any, onError: void, onCorrect?: void) => void
  }
}
const useConnect = ({ correct, preview, id, zones, onDone }: IUseSort): ISortReturn => {

  const [isDragging, setDragging] = React.useState(false)
  const [dragOverZone, setDragOverZone] = React.useState(null)
  const [state, setState] = React.useState<SortState>('init')
  const [selection, setSelection] = React.useState({})
  const [options, setOptions] = React.useState([])

  const refs = {}
  refs['constraintsRef'] = React.useRef(null)
  refs['dragRef'] = React.useRef(null)
  // eslint-disable-next-line
  refs['dropzonesRef'] = zones?.reduce((refs: any, z) => ({ ...refs, [z.id]: React.createRef() }), {})
  // eslint-disable-next-line
  const controls = {}

  const activeZone = () => {
    if(!refs['dropzonesRef']) return false
    return Object.keys(refs['dropzonesRef']).filter(z => refs['dropzonesRef'][z].current?.isActive)[0] || false
  }

  const actions = {
    setDragging(boolean) {
      setDragging(boolean)
    },
    handleAnswer(zone, option) {

      const startIndex = options.findIndex(o => o?.id === option.id)

      const new_selection = { ...selection }
      const new_options = [...options]

      // Set the values to selection
      //const new_zone_selection = [...new_selection[zone]]
      new_selection[zone] = new_selection[zone] || []
      new_selection[zone].push(option)

      // Replace current dragoption with null
      new_options[startIndex] = null

      setSelection(new_selection)
      setOptions(new_options)

      if (new_options.filter(o => o !== null).length === 0) {
        onDone()
      }

    },
    onDrag(event: DragEvent, callback) {

      Object.keys(refs['dropzonesRef']).map((zone) => {

        if (!refs['dropzonesRef'][zone].current) return
        const zoneBound = refs['dropzonesRef'][zone].current.getBoundingClientRect()

        if (event.pageX > zoneBound.left && event.pageX < (zoneBound.left + zoneBound.width)) {

          if (event.pageY > zoneBound.top && event.pageY < (zoneBound.top + zoneBound.height)) {

            refs['dropzonesRef'][zone].current.isActive = true
            if (dragOverZone !== zone) {
              setDragOverZone(zone)

              callback()
            }

          } else {
            refs['dropzonesRef'][zone].current.isActive = false
          }

        } else {
          refs['dropzonesRef'][zone].current.isActive = false
        }

      })
    },
    onDragEnd(option, onError, onCorrect) {
      setDragging(false)

      const endZone = dragOverZone

      if (correct && option.zone !== endZone || !endZone) {

        // Callback to add animation for instance
        onError()

      } else {

        if(onCorrect) {
          onCorrect(correct)
        }

        actions.handleAnswer(endZone, option)

      }
    },
    reset() {
      setState('init')
      setOptions(mapOptions(zones, !preview))
      setSelection({})
    }
  }

  React.useEffect(() => {
    if (activeZone()) {
      setDragOverZone(activeZone())
    }
  }, [refs])

  React.useMemo(() => {
    actions.reset()
  }, [id, zones])

  return { dragOverZone, options, isDragging, controls, refs, state, selection, actions }

}

export default useConnect
