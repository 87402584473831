import TextContent from '@pidk/renderer/src/components/Blocks/RichText/RichText'

import useProjectMedia from '../../../hooks/useProjectMedia'
import type { SlideComponent } from '../../../types/slide'
import Component, { Area, Inner, Button } from '../Slide.styled'

const SlideIntro: SlideComponent = ({
  deck
}) => {
  const [deckImageMedia] = useProjectMedia([deck.image])

  return (
    <Component>
      <Inner>
        <Area>
          <TextContent
            content={[
              {
                text: deck.name,
                format: 'super'
              }
            ]}
          />
          <Button onClick={() => alert('show start modal and redirect')}>Aan de slag</Button>
        </Area>
        <Area>
          {deckImageMedia && (
            <img
              src={deckImageMedia.url}
              alt={deckImageMedia.name}
            />
          )}
        </Area>
      </Inner>
    </Component>
  )
}

SlideIntro.schema = {
  name: 'Intro',
  key: 'intro',
  defaultFieldValues: {},
  fields: []
}

export default SlideIntro
