import type { IFieldSchema } from './../../types/fields'
import { FieldType } from './../../types/fields'

const LayoutPropSchema: IFieldSchema = {
  name: 'Layout',
  key: 'layout',
  defaultFieldValues: {},
  fields: [
    {
      key: 'layout',
      type: FieldType.GROUP,
      label: 'Layout',
      fields: [
        {
          key: 'direction',
          label: 'Direction',
          type: FieldType.CHOICE,
          component: 'button',
          options: [
            {
              value: 'y',
              label: 'Vertical'
            },
            {
              value: 'x',
              label: 'Horizontal'
            }
          ]
        },
        {
          key: 'halign',
          label: 'Horizontal Align',
          type: FieldType.CHOICE,
          component: 'button',
          options: [
            {
              value: 'left',
              label: 'Left'
            },
            {
              value: 'center',
              label: 'Center'
            },
            {
              value: 'right',
              label: 'Right'
            }
          ]
        },
        {
          key: 'valign',
          label: 'Vertical Align',
          type: FieldType.CHOICE,
          component: 'button',
          options: [
            {
              value: 'top',
              label: 'Top'
            },
            {
              value: 'center',
              label: 'Center'
            },
            {
              value: 'bottom',
              label: 'Bottom'
            }
          ]
        }
      ]
    }
  ]
}

export default LayoutPropSchema
