import type { DeckFormatted, ProjectFormatted } from '@pidk/api/src/types/api'
import type defaultProjectTheme from '@pidk/renderer/src/theme/default'

import type { Properties } from './properties'

export type FieldValues = Record<string, any>

export type Project = Omit<ProjectFormatted, 'decks' | 'theme'> & {
  decks: DeckParsed[]
  theme: ProjectTheme
  overview: any
}

export type ProjectTheme = typeof defaultProjectTheme

export type DeckParsed = Omit<DeckFormatted, 'data'> & {
  slides: ISlide[]
  hasVoting?: boolean
}

// TODO: move this to a separate file
export enum SlideType {
  INTRO = 'INTRO',
  CHOICE = 'CHOICE',
  LAYOUT = 'LAYOUT'
}

export interface ISlide {
  id: string
  order: number
  name: string
  type: SlideType
  active: boolean
  blocks?: IBlock[]
  areas?: IArea[]
  data: FieldValues
  properties: Properties
}

export interface IBlock {
  type: string
  key: string
  area: string
  fieldsValues?: FieldValues
  order?: number
}

export interface IArea {
  id: string
  name: string
  blocks: string[]
  data: FieldValues
  properties: Properties
}

// Normalized entities

export type ProjectNormalized = Omit<Project, 'decks' | 'media'> & {
  decks: string[]
  media: string[]
}

export type DeckNormalized = Omit<DeckParsed, 'slides'> & {
  slides: string[]
}

export enum ComposeView {
  PROJECT = 'PROJECT',
  DECK = 'DECK',
  MEDIA = 'MEDIA',
  OVERVIEW = 'OVERVIEW',
  THEME = 'THEME'
}
