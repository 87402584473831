import VideoPlayer from '@pidk/common/src/components/VideoPlayer'
import { FieldType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import styled from 'styled-components'

type BlockComponent = React.FC<IVideo> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

interface IVideo {
  url?: string
  youtube?: string
  autoPlay?: boolean
}

const Component = styled.div<any>`
  background: black;
  width: 100%;
  max-width: 80vw;
`

const Video: BlockComponent = ({
  url,
  youtube,
  autoPlay
}: IVideo) => {

  return (
    <Component>
      <VideoPlayer
        source={url}
        youtube={youtube}
        controls
        autoPlay={autoPlay ? true : false}
      />
    </Component>
  )
}

Video.schema = {
  name: 'Video',
  key: 'video',
  defaultFieldValues: {
    autoPlay: false
  },
  fields: [
    {
      key: 'url',
      type: FieldType.TEXT,
      label: 'Vimeo Mp4 Url',
      instructions: 'Copy paste mp4 url from vimeo'
    },
    {
      key: 'youtube',
      type: FieldType.TEXT,
      label: 'Youtube ID',
      instructions: 'Paste Youtube ID here. Leave rest empty.'
    },
    {
      key: 'autoPlay',
      type: FieldType.BOOLEAN,
      label: 'Autoplay'
    }
  ]
}

Video.Styled = Component

export default Video
