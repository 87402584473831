import { msToSec } from '@pidk/renderer/src/utils/time'

interface ITransitionOutput {
  initial?: any
  animate?: any
  exit?: any
}

interface ITransitionProps {
  preset?: string
  delay?: number
}

const defaultTransitions = {
  'none': {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1
  },
  'default': {
    y: 40,
    opacity: 0
  },
  'fadeIn': {
    opacity: 0,
    scale: 0.99
  },
  'fadeOut': {
    opacity: 0,
    scale: 0.95
  },
  'fadeUp': {
    y: 40,
    opacity: 0
  },
  'fadeDown': {
    y: -40,
    opacity: 0
  },
  'fadeLeft': {
    x: '-10%',
    opacity: 0
  },
  'fadeRight': {
    x: '10%',
    opacity: 0
  }
}

const defaultExitTransitions = {
  'none': defaultTransitions['none'],
  'default': defaultTransitions['fadeUp'],
  'fadeIn': defaultTransitions['fadeOut'],
  'fadeUp': defaultTransitions['fadeUp'],
  'fadeDown': defaultTransitions['fadeDown'],
  'fadeLeft': defaultTransitions['fadeLeft'],
  'fadeRight': defaultTransitions['fadeRight']
}

export const backgroundImageTransition = ({ preset }: ITransitionProps): ITransitionOutput => {
  return {
    initial: {
      ...defaultTransitions[preset || 'fadeIn'],
      transition: {
        delay: 0,
        duration: 0.275
      }
    },
    animate: {
      ...defaultTransitions['none'],
      transition: {
        delay: 0,
        duration: 0.275
      }
    },
    exit: {
      ...defaultExitTransitions[preset],
      transition: {
        delay: (preset !== 'none' ? 0.325 : 0),
        duration: (preset !== 'none' ? 0.275 : 0)
      }
    }
  }
}

export const slideContentTransition = ({ preset, delay }: ITransitionProps): ITransitionOutput => {
  return {
    initial: {
      ...defaultTransitions[preset || 'default'],
      transition: {
        delay: msToSec(delay) + 0.1
      }
    },
    animate: {
      ...defaultTransitions['none'],
      transition: {
        delay: msToSec(delay) + 0.1,
        duration: 0.25
      }
    },
    exit: {
      ...defaultExitTransitions[preset],
      transition: {
        delay: 0,
        duration: (preset !== 'none' ? 0.2 : 0)
      }
    }
  }
}

export const areaContentTransition = ({ preset, delay }: ITransitionProps): ITransitionOutput => {
  return {
    initial: {
      ...defaultTransitions[preset || 'none'],
      transition: {
        delay: msToSec(delay) + 0.275
      }
    },
    animate: {
      ...defaultTransitions['none'],
      transition: {
        delay: msToSec(delay) + 0.275
      }
    },
    exit: {
      ...defaultExitTransitions[preset],
      transition: {
        delay: 0
      }
    }
  }
}
