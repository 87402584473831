import type { MediaFormatted } from '@pidk/api/src/types/api'
import SFX from '@pidk/common/src/sfx'
import { useEffect, useState } from 'react'
import useSound from 'use-sound'

interface IMemoryItem {
  id: string
  parentId: string
  image?: MediaFormatted
  label?: string
}

interface IUseMemory {
  id: string
  list: IMemoryItem[]
  onDone: () => void
}

interface IUseMemoryReturn {
  items: IMemoryItem[]
  visibleItems: number[]
  finishedItems: number[]
  handleItemClick: (number) => void
  resetItems: () => void
}

const useMemory = ({ id, list, onDone }: IUseMemory): IUseMemoryReturn => {

  const [ items, setItems ] = useState([])
  const [ visibleItems, setVisibleItems ] = useState([])
  const [ finishedItems, setFinishedItems ] = useState([])

  const [ errorSFX ] = useSound(SFX.negativeDongs)
  const [ correctSFX ] = useSound(SFX.correctBell)

  const handleItemClick = (index) => {
    if (!finishedItems.includes(index)) {
      switch (visibleItems.length) {
        case 0:
          setVisibleItems([index])
          break
        case 1:
          if (visibleItems[0] !== index) {
            setVisibleItems(visibleItems.concat(index))
            checkItems(visibleItems[0], index)
          }
          break
        case 2:
          setVisibleItems([index])
          break
        default:
          setVisibleItems([])
      }
    }
  }

  const checkItems = (firstIndex, secondIndex) => {

    if(
      firstIndex !== secondIndex &&
      list[firstIndex].parentId === list[secondIndex].parentId
    ) {
      setFinishedItems([...finishedItems, firstIndex, secondIndex])
      correctSFX()
    } else {
      errorSFX()
      setTimeout(() => {
        setVisibleItems([])
      }, 1000)
    }
  }

  const resetItems = () => {
    setFinishedItems([])
    setVisibleItems([])
    shuffleItems(list)
  }

  const shuffleItems = (items) => {
     const shuffled = items.sort(() => {
       return 0.5 - Math.random()
    })
    setItems(shuffled)
  }

  useEffect(() => {
    if (finishedItems.length > 0 && finishedItems.length === list.length) {
      onDone()
    }
  }, [finishedItems])

  useEffect(() => {
    shuffleItems(list)
  }, [id, list])

  return {
    items,
    visibleItems,
    finishedItems,
    handleItemClick,
    resetItems
  }
}

export default useMemory
