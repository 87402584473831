import { SlideType } from '@pidk/compose/src/types/compose'

import SlideIntro from '../components/Slide/variants/SlideIntro'
import SlideLayout from '../components/Slide/variants/SlideLayout'

export const SlideTypeMapper = {
  [SlideType.INTRO]: SlideIntro,
  [SlideType.LAYOUT]: SlideLayout
}

export const getSlideTypeComponent = (slideType: SlideType) => {
  const SlideComponent = SlideTypeMapper[slideType]

  if (process.env.NODE_ENV === 'development') {
    if (!SlideComponent) {
      throw new Error(`Could not find Slide component for type ${slideType}`)
    }

    if (!SlideComponent.schema) {
      throw new Error(`Could not find Slide schema for type ${slideType}`)
    }
  }

  return SlideComponent
}
