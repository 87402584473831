/* eslint-disable */
import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import styled, { css } from 'styled-components'
import { Option } from './../../common/Option'

import { DragItem, Label } from '../Connect/Connect.styled'

export const Base = styled.div<any>`
  width: 100%;
  height: 100%;
`

const Zone = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 2vh;
  width: 100%;
  height: 100%;
`
export const Title = styled.span<any>`
  position: absolute;
  top: 0;
  font-size: ${({ theme }) => theme.fonts.fontSize['4xl']};
  margin: 2rem;
  font-weight: bold;
`

export const Dropzone = styled(Zone) <any>`
  display: flex;
  flex-wrap: wrap;
  outline: 2px solid rgba(255,2555,255, 0.1);
  transition: transform 200ms ease-in-out;
  position: relative;

  ${props => props.$isActive && css`
    outline-color: white;
    transform: scale(1.05);
  `}

  ${({ theme }) => css`
      background-color: ${getProjectThemeColor(theme.colors, (theme.sort?.dropzone?.backgroundColor || 'transparent'))};
      color: ${getProjectThemeColor(theme.colors, (theme.sort?.dropzone?.color || 'white'))};
  `}


  // TODO: needs a util function
  ${props => props.background?.color && css`
    background-color: ${getProjectThemeColor(props.theme.colors, (props.background.color))};
  `}

  // TODO: needs a util function
  ${props => props.background?.image && css`
    background-image: url('${props.background.image}');
    background-size: ${props.background.size};
    background-position: ${props.background.position};
    background-repeat: ${props.background.repeat};
  `}

  ${Option} {

    pointer-events: none;

    font-size: clamp(0.8em, 1.1vw,  24px);

    ${({ theme }) => css`
      background-color: ${getProjectThemeColor(theme.colors, (theme.dropzone?.backgroundColor || 'secondary'))};
      color: ${getProjectThemeColor(theme.colors, (theme.dropzone?.color || 'white'))};
    `}

    ${({ $isDone, theme }) => $isDone && css`
      background-color: ${getProjectThemeColor(theme.colors, (theme.sort?.option?.done?.backgroundColor || 'secondary'))};
      color: ${getProjectThemeColor(theme.colors, (theme.sort?.option?.done?.color || 'white'))};
    `}
  }

  ${DragItem} {
    width: 12vh;
    height: 12vh;
  }

  ${Title} {

    ${({ $align }) => {

    if ($align === 'top left') {
      return css`
            top: 0;
            left: 0;
          `
    } else if ($align === 'top right') {
      return css`
            top: 0;
            right: 0;
          `
    }

  }}
  }
`



export const Dragzone = styled(Zone)`
  width: auto;
  height: auto;

  ${DragItem} {
    width: 15vh;
    height: 15vh;
  }

  ${Option} {

  ${({ theme }) => theme && css`
    background-color: ${getProjectThemeColor(theme.colors, (theme.sort?.option?.default?.backgroundColor || 'secondary'))};
    color: ${getProjectThemeColor(theme.colors, (theme.sort?.option?.default?.color || 'white'))};
    &:hover {
      background-color: ${getProjectThemeColor(theme.colors, (theme.sort?.option?.default?.backgroundColor || 'secondary'))};
      color: ${getProjectThemeColor(theme.colors, (theme.sort?.option?.default?.color || 'white'))};
    }
  `}
}
`
