import type { IFieldSchema } from './../../types/fields'
import { FieldType } from './../../types/fields'

const ColorsPropSchema: IFieldSchema = {
  name: 'Colors',
  key: 'colors',
  defaultFieldValues: {},
  fields: [
    {
      key: 'colors',
      type: FieldType.GROUP,
      label: 'Colors',
      fields: [
        {
          key: 'defaultColor',
          type: FieldType.COLOR,
          label: 'Default'
        },
        {
          key: 'accentColor',
          type: FieldType.COLOR,
          label: 'Accent'
        }
      ]
    }
  ]
}

export default ColorsPropSchema
